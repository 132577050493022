document.addEventListener("turbolinks:load", () => {
  let timerid = null;
  const form = document.querySelector("form.filter[data-remote='true']");
  if (!form) return;

  const handleChange = () => {
    clearTimeout(timerid);
    timerid = setTimeout(() => {
      form.querySelector(`[type="submit"]`).click();
    }, 350);
  };

  form.addEventListener("keyup", handleChange);
  form.querySelectorAll("select").forEach(el => el.addEventListener("change", handleChange));
});
