document.addEventListener("turbolinks:load", () => {
  const newResourceForm = document.querySelector("#new-resource");
  if (!newResourceForm) return;

  const formToggler = event => {
    event.preventDefault();
    newResourceForm.classList.toggle("active");
  };

  document.querySelectorAll(".action-new").forEach(el => {
    el.addEventListener("click", formToggler);
  });
  newResourceForm.querySelector(".cancel-resource").addEventListener("click", formToggler);
});
