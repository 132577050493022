document.addEventListener("turbolinks:load", () => {
  const pagination = document.querySelectorAll(".pagination");
  if (pagination.length === 0) return;
  let pushedState = false;

  document.querySelector("#main").addEventListener("click", event => {
    if (event.target.classList.contains("page-link")) {
      if (!pushedState) {
        // window.history.pushState({ turbolinks: {} }, "", event.target.href);
        // window.history.pushState(window.history.state, "", event.target.href);
        Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(event.target.href, Turbolinks.uuid());
        pushedState = true;
      } else {
        // window.history.replaceState({ turbolinks: {} }, "", event.target.href);
        Turbolinks.controller.replaceHistoryWithLocationAndRestorationIdentifier(event.target.href, Turbolinks.uuid());
      }
    }
  });
});
