import getSlug from "speakingurl";

const options = {
  custom: {
    ж: "z",
    Ж: "Z",
    х: "h",
    Х: "H",
    ч: "c",
    Ч: "C",
    ш: "s",
    Ш: "S"
  }
};

document.addEventListener("turbolinks:load", () => {
  document.querySelectorAll("input[name*='slug']").forEach(output => {
    const input = output.closest("form").querySelector("input[name*='name']");
    if (!input) return;
    input.addEventListener("input", () => {
      output.value = getSlug(input.value, options);
    });
  });
});
